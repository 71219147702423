var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":this.$props.head,"items":this.$props.data,"item-class":"primary--text","mobile-breakpoint":"960"},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('img',{staticStyle:{"width":"65px","height":"70px","margin":"10px","border-radius":"50px"},attrs:{"src":item.image != '' ? _vm.imgUrl + item.image : _vm.blankIMG,"width":"50px"}})]}},{key:"item.productDescription",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),_c('p',{staticStyle:{"color":"gray"}},[_vm._v(_vm._s(item.other_code))])]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"mt-4",staticStyle:{"color":"black"},attrs:{"outlined":"","dense":"","placeholder":"0","type":"number","rules":_vm.generalRule,"readonly":_vm.editAction == false && _vm.addAction == false},model:{value:(item.number),callback:function ($$v) {_vm.$set(item, "number", $$v)},expression:"item.number"}})]}},{key:"item.real_number",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"mt-4",staticStyle:{"color":"black"},attrs:{"outlined":"","dense":"","type":"number","placeholder":"0","rules":_vm.generalRule,"readonly":_vm.editAction == false &&
        _vm.addAction == false &&
        _vm.auditAction == false &&
        _vm.returnStatus != 1},model:{value:(item.real_number),callback:function ($$v) {_vm.$set(item, "real_number", $$v)},expression:"item.real_number"}})]}},{key:"item.ReturnNumber",fn:function(ref){
        var item = ref.item;
return [_c('v-text-field',{staticClass:"mt-4",staticStyle:{"color":"black"},attrs:{"outlined":"","dense":"","placeholder":"0","type":"number","rules":_vm.generalRule},model:{value:(item.ReturnNumber),callback:function ($$v) {_vm.$set(item, "ReturnNumber", $$v)},expression:"item.ReturnNumber"}})]}},{key:"item.receive_number",fn:function(ref){
        var item = ref.item;
return [_c('v-text-field',{staticClass:"mt-4",staticStyle:{"color":"black"},attrs:{"outlined":"","dense":"","placeholder":"0","type":"number","rules":_vm.generalRule},model:{value:(item.receive_number),callback:function ($$v) {_vm.$set(item, "receive_number", $$v)},expression:"item.receive_number"}})]}},{key:"item.deleteProduct",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){return _vm.$emit('deleteitem', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.delete")))])])]}},{key:"item.note",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkStrLength(item.note))+" "),(item.note.length > 30)?_c('span',{staticClass:"blue--text"},[_vm._v(".....more")]):_vm._e()]}},{key:"item.address",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkStrLength(item.address))+" "),(item.address.length > 30)?_c('span',{staticClass:"blue--text"},[_vm._v(".....more ")]):_vm._e()]}},{key:"item.category",fn:function(){return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.commodity"))+" ")]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }