<template>
  <v-data-table
    :headers="this.$props.head"
    :items="this.$props.data"
    item-class="primary--text"
    mobile-breakpoint="960"
  >
    <template v-slot:[`item.image`]="{ item }">
      <img
        :src="item.image != '' ? imgUrl + item.image : blankIMG"
        width="50px"
        style="width: 65px; height: 70px; margin: 10px; border-radius: 50px"
      />
    </template>

    <template v-slot:[`item.productDescription`]="{ item }">
      {{ item.name }}
      <p style="color: gray">{{ item.other_code }}</p>
    </template>

    <template v-slot:[`item.number`]="{ item }">
      <v-text-field
        outlined
        dense
        style="color: black"
        class="mt-4"
        v-model="item.number"
        placeholder="0"
        type="number"
        :rules="generalRule"
        :readonly="editAction == false && addAction == false"
      />
    </template>

    <template v-slot:[`item.real_number`]="{ item }">
      <v-text-field
        outlined
        dense
        style="color: black"
        class="mt-4"
        v-model="item.real_number"
        type="number"
        placeholder="0"
        :rules="generalRule"
        :readonly="
          editAction == false &&
          addAction == false &&
          auditAction == false &&
          returnStatus != 1
        "
      />
    </template>

    <template v-slot:[`item.ReturnNumber`]="{ item }">
      <v-text-field
        outlined
        dense
        style="color: black"
        class="mt-4"
        v-model="item.ReturnNumber"
        placeholder="0"
        type="number"
        :rules="generalRule"
      />
    </template>

    <template v-slot:[`item.receive_number`]="{ item }">
      <v-text-field
        outlined
        dense
        style="color: black"
        class="mt-4"
        v-model="item.receive_number"
        placeholder="0"
        type="number"
        :rules="generalRule"
      />
    </template>

    <template v-slot:[`item.deleteProduct`]="{ item }">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            @click="$emit('deleteitem', item)"
            color="red"
            v-bind="attrs"
            v-on="on"
          >
            mdi-delete
          </v-icon>
        </template>
        <span>{{ $vuetify.lang.t("$vuetify.delete") }}</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.note`]="{ item }">
      {{ checkStrLength(item.note) }}
      <span class="blue--text" v-if="item.note.length > 30">.....more</span>
    </template>

    <template v-slot:[`item.address`]="{ item }">
      {{ checkStrLength(item.address) }}
      <span class="blue--text" v-if="item.address.length > 30">.....more </span>
    </template>

    <template v-slot:[`item.category`]>
      {{ $vuetify.lang.t("$vuetify.commodity") }}
    </template>
  </v-data-table>
</template>

<script>
import serverConfig from "@/utils/serverConfig";
export default {
  name: "stockOutProductTable",
  props: {
    head: Array,
    data: Array,
    search: String,
    dialog: Boolean,
    myObj: Object,
    addAction: Boolean,
    editAction: Boolean,
    auditAction: Boolean,
    abolishAction: Boolean,
    returnStatus: Number,
  },
  data() {
    return {
      blankIMG: serverConfig.blank_profile_img,
      imgUrl: serverConfig.img_url,
      generalRule: [(v) => !!v || "this field is required"],
    };
  },
  methods: {
    checkStrLength(item) {
      if (item.length > 30) {
        return item.substring(0, 30);
      } else {
        return item;
      }
    },
  },
};
</script>
